import React  from 'react';
import { DirectLine } from 'botframework-directlinejs';
import ReactWebChat, { createStore } from 'botframework-webchat';
import { useAuth0 } from '@auth0/auth0-react';

const WebChat = ({ directLineToken, auth0AccessToken, auth0IdToken }) => {
  const { user } = useAuth0();
  const userId = 'dl_' + user.sub;

  console.log('auth0Token',auth0AccessToken);
  const directLine = new DirectLine({
    token: directLineToken,
    user: {
      id: userId,
      name: user.name,
      token: auth0AccessToken
    },
    domain: 'https://europe.directline.botframework.com/v3/directline',
    // pollingInterval: 500,
    conversationStartProperties: {
      locale: "en-AU"
    }
  });

  const store = createStore({}, ({ dispatch }) => next => action => {
      if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
        new Promise(function(resolve) {
          dispatch({
            type: "DIRECT_LINE/POST_ACTIVITY",
            meta: {
              method: "keyboard",
            },
            payload: {
              activity: {
                channelData: {
                  postBack: true,
                },
                name: "login",
                type: "event",
                from: {
                  id: userId,
                  name: user.name
                },
                value: auth0AccessToken
              },
            }
          });
          setTimeout(() => resolve(1), 1000);
        }).then(function(result) {
          let payload = {
            "ExternalUserToken": auth0AccessToken, // Use the accessToken from state
            "ExternalUserIdToken": auth0IdToken.__raw,
            "ExternalUserId": userId,
            "ExternalUserName": user.name,
            "ExternalUserEmail": user.email,
            pageURL: window.location.href,
          };
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'pvaSetContext',
              value: payload
            }
          });
          console.log('transfered', payload, 'result',result);
        });
      }
      return next(action);
    }
  );


  return (
    <div className="shadow-md h-3/4">
      <ReactWebChat directLine={directLine} store={store} userID={userId} username={user.name} />
    </div>
  );
};

export default WebChat;