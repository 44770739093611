import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';

ReactDOM.render(
  <Auth0Provider
  domain="dev-wnppmrrjx3p8l8gf.us.auth0.com"
  clientId="Qklosh9OaZCPURjLxQ3l4Wa0hRQcJgYY"
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
