
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton, LogoutButton, Profile, WebChat } from './components';

const App = () => {
  // const tokenEndpoint = 'https://default957cceeb98294dd9a6808ca4d2fe58.f0.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cr405_dickerdata_2/directline/token?api-version=2022-03-01-preview';
  const tokenSource = 'https://europe.directline.botframework.com/v3/directline/tokens/generate';
  const directLineKey = '99Vst5CIMSc.kxEfgY6gO5g3GjUHtz_JrPqw_psY-vWJUt6D2hV-6pg';

  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  const [auth0AccessToken, setAuth0AccessToken] = useState(null);
  const [auth0IdToken, setAuth0IdToken] = useState(null);
  const [directLineToken, setDirectLineToken] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({ scope: "user.read openid profile" })
        .then(setAuth0AccessToken)
        .catch(console.error);

      getIdTokenClaims()
        .then(setAuth0IdToken)
        .catch(console.error);
    }
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    const fetchDirectLineToken = async () => {
      try {
        const response = await fetch(tokenSource, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${directLineKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: {
              id: `dl_${user.sub}`,
              name: user.name,
            }
          }),
        });
        const data = await response.json();
        // console.log('directline', data.auth0AccessToken);
        // console.log('auth0', 'access',auth0Token,'id', auth0IdToken);
        setDirectLineToken(data.token);
      } catch (error) {
        console.error('Failed to fetch Direct Line token:', error);
      }
    };

    if (isAuthenticated && user && auth0AccessToken && auth0IdToken) {
      fetchDirectLineToken();
    }
  }, [isAuthenticated, user, auth0AccessToken, auth0IdToken]);

  return (
    <div className="flex h-screen">
      <div className="w-1/3 bg-gray-200 p-4">
        <LoginButton />
        <LogoutButton />
        {isAuthenticated && auth0IdToken ? (<Profile auth0IdToken={auth0IdToken} />) : (<div />)}
      </div>
      <div className="w-2/3 bg-gray-40 p-4 h-screen">
        {isAuthenticated && directLineToken && auth0AccessToken && auth0IdToken? (
          <WebChat key={directLineToken} directLineToken={directLineToken} auth0AccessToken={auth0AccessToken} auth0IdToken={auth0IdToken} />
        ) : (
          <div>Please log in to chat with the bot</div>
        )}
      </div>
    </div>
  );
};

export default App;
