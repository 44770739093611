import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = (auth0IdToken) => {
    const { user, isAuthenticated } = useAuth0();

    return isAuthenticated ? (
    <div>
      <div class="flex items-center py-4">
        <div class="flex-shrink-0 w-16">
            <img src={user.picture} alt={user.name} />
        </div>
        <div class="ml-4">
          <h2 class="text-2xl font-semibold user-name">{user.name}</h2>
          <p class="text-base text-gray-600 user-email">{user.email}</p>
        </div>
      </div>
  
      <div class="mt-4">
        <pre class="rounded bg-gray-100 p-4">
          <code id="profile-data" class="language-json">{JSON.stringify(auth0IdToken, null, 4)}</code>
        </pre>
      </div>
    </div>
  ) : null;
};

export default Profile;